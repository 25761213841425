module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"style":{"overlay":{"zIndex":100,"display":"flex","alignContent":"center","justifyContent":"center","alignItems":"center"},"content":{"background":"#E3DECC","position":"relative","maxWidth":"95%","maxHeight":"calc(100vh - 40px)","overflow":"hidden","display":"flex","flexDirection":"column","padding":0,"border":0,"borderRadius":0,"inset":0}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.4.1","defaultLang":"nl","currency":"eur","openCartOnAdd":true,"useSideCart":true,"locales":{"nl":{"actions":{"checkout":"Afrekenen"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    }]
